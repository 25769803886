import React, { useContext } from 'react'

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'

import { UserContext, userCanEdit } from '../../contexts/UserContext'
 
import DateTimeDisplay from '../../components/typography/DateTimeDisplay'

import {
    Box,
    Divider,
    Stack,
    Heading,
    Spacer,
    useColorModeValue,
} from '@chakra-ui/react';

dayjs.extend(utc)

const EventDateTimeDisplay = ( props ) => {

    const event = props.event

    const { user } = useContext(UserContext)

    return (
        <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={4}
            >
            <Stack>
                <Heading fontSize="1xl">Scheduled</Heading>
                <Spacer />
            </Stack>
            
            <Divider/>

            <Stack spacing={4} direction={'row'}>
                <DateTimeDisplay editable={userCanEdit(user, event) || undefined} date={dayjs(event.startsAt).utc().toString()} />
                <Divider orientation="vertical" mx={4}/>
                <DateTimeDisplay editable={userCanEdit(user, event) || undefined} date={dayjs(event.endsAt).utc().toString()} rtl={true}/>
            </Stack>
        </Box>
    )
};

export default EventDateTimeDisplay