import React from 'react'

import {
    Box,
    Heading,
    Stack,
    Divider,
    useColorModeValue
} from '@chakra-ui/react';
import VNCViewer from '../../components/vnc/VNCViewer'

const CameraVNC = () => {

    return (
        <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={4}
            >
            <Stack spacing={2}>
                <Heading fontSize="1xl">Remote viewing</Heading>
                
                <Divider/>

                <VNCViewer url='ws://0.tcp.eu.ngrok.io:14187'/>
            </Stack>
        </Box>
    )
};

export default CameraVNC