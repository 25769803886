import {
    useEditableControls,
    ButtonGroup,
    IconButton,
} from '@chakra-ui/react';
import {
    CheckIcon,
    CloseIcon
} from '@chakra-ui/icons';

function EditableControls() {
    const {
        isEditing,
        getSubmitButtonProps,
        getCancelButtonProps,
    } = useEditableControls()

    return isEditing ? (
        <ButtonGroup size="sm">
            <IconButton icon={<CheckIcon />} {...getSubmitButtonProps()} />
            <IconButton icon={<CloseIcon />} {...getCancelButtonProps()} />
        </ButtonGroup>
    ) : null
}

export default EditableControls