import React from 'react';
import {
    Button
} from '@chakra-ui/react';

const ActionButton = ({ label, ...props }) => {
    return <Button
        bg={'blue.400'}
        color={'white'}
        _hover={{
            bg: 'blue.500',
        }}
        {...props}
        >{label}</Button>
};

export default ActionButton