import React, { useEffect, useState, useContext, useCallback } from 'react'
import { Link as RouterLink, useHistory } from "react-router-dom"

import { UserContext } from '../../contexts/UserContext'
 
import Title from '../../components/typography/Title'
import AddButton from '../../components/forms/buttons/AddButton'
import ModalForm from '../../components/modal/ModalForm'
import CameraForm from './CameraForm'

import {
    Container,
    Stack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Spinner,
    Flex,
    Spacer,
    Link,
} from '@chakra-ui/react';

import API from '../../backend/API'

const Cameras = () => {

    const { user, setUser } = useContext(UserContext)
    const history = useHistory()

    const [cameras, setCameras] = useState(null)
    const [isModalOpen, setModalIsOpen] = useState(false)

    const listCameras = useCallback(() => {
        API.Cameras_List( {user}, null, (json)=>{
            setCameras( json.items )
            setModalIsOpen(false)
        }, (error)=>{
            setCameras( null )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }, [user, setUser])

    useEffect(listCameras, [listCameras])

    return (
        <Container maxW={'6xl'} mt={10}>
            <Stack spacing={4}>
                <Flex>
                    <Title>Cameras</Title>
                    <Spacer />
                    { !cameras && (<Spinner />)}
                    { cameras && <AddButton onClick={()=>setModalIsOpen(true)} /> }
                </Flex>
                { cameras && (
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Profile</Th>
                                <Th>Model</Th>
                                <Th>OS</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                cameras.sort( (a, b) => (a.createdAt > b.createdAt) ? 1 : -1 ).map( item => {
                                    return (
                                        <Tr key={item.id}>
                                            <Td><Link as={RouterLink} to={`cameras/${item.id}`} >{item.profile}</Link></Td>
                                            <Td>{item.model}</Td>
                                            <Td>{item.os}</Td>
                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </Table>
                )}
            </Stack>

            <ModalForm isOpen={isModalOpen} onClose={()=>setModalIsOpen(false)} title="Create camera">
                <CameraForm onSuccess={(json)=>history.push(`/cameras/${json.item.id}`)} />
            </ModalForm>
        </Container>
    )
}

export default Cameras