import React, { useState, useContext } from 'react'

import {
    Stack,
    Spinner,
} from '@chakra-ui/react';

import API from '../../../backend/API'

import { UserContext } from '../../../contexts/UserContext'

import AlertDestructiveConfirm from '../../../components/alerts/AlertDestructiveConfirm'

import PromoteButton from '../../../components/forms/buttons/PromoteButton'
import DemoteButton from '../../../components/forms/buttons/DemoteButton'
import DeleteButton from '../../../components/forms/buttons/DeleteButton'

function WhatsAppUserListItem( props ) {

    const event = props.event
    const item = props.item
    const setGroupUsers = props.setGroupUsers
    const canAdminister = props.canAdminister

    const { user, setUser } = useContext(UserContext)

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isPromoteUserAlertIsOpen, setPromoteUserAlertIsOpen] = useState(false)
    const [isDemoteUserAlertIsOpen, setDemoteUserAlertIsOpen] = useState(false)
    const [isRemoveUserAlertIsOpen, setRemoveUserAlertIsOpen] = useState(false)

    const promoteUser = () => {
        setIsSubmitting( true )
        setPromoteUserAlertIsOpen( false )
        API.Events_WhatsApp_Users_Promote( {user, id:event.id, jid:item.jid}, null, (json)=>{
            setIsSubmitting( false )
            setGroupUsers( json.items )
        }, (error)=>{
            console.log( error )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }
    const demoteUser = () => {
        setIsSubmitting( true )
        setDemoteUserAlertIsOpen( false )
        API.Events_WhatsApp_Users_Demote( {user, id:event.id, jid:item.jid}, null, (json)=>{
            setIsSubmitting( false )
            setGroupUsers( json.items )
        }, (error)=>{
            console.log( error )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }
    const removeUser = () => {
        setIsSubmitting( true )
        setRemoveUserAlertIsOpen( false )
        API.Events_WhatsApp_Users_Remove( {user, id:event.id, jid:item.jid}, null, (json)=>{
            setIsSubmitting( false )
            setGroupUsers( json.items )
        }, (error)=>{
            console.log( error )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }

    return (
        <>
            <Stack direction={"row"} spacing={2} shouldWrapChildren={true}>
                { isSubmitting && <Spinner /> }
                { canAdminister && !isSubmitting && ( item.isAdmin ? <DemoteButton size="sm" onClick={()=>setDemoteUserAlertIsOpen(true)} /> : <PromoteButton size="sm" onClick={()=>setPromoteUserAlertIsOpen(true)} /> ) }
                { canAdminister && !isSubmitting && <DeleteButton size="sm" onClick={()=>setRemoveUserAlertIsOpen(true)} />}
                { !canAdminister && item.isAdmin && <span>(admin)</span> }
            </Stack>

            <AlertDestructiveConfirm isOpen={isPromoteUserAlertIsOpen} setIsOpen={setPromoteUserAlertIsOpen} title="Promote user" onConfirm={()=>promoteUser()} />
            <AlertDestructiveConfirm isOpen={isDemoteUserAlertIsOpen} setIsOpen={setDemoteUserAlertIsOpen} title="Demote user" onConfirm={()=>demoteUser()} />
            <AlertDestructiveConfirm isOpen={isRemoveUserAlertIsOpen} setIsOpen={setRemoveUserAlertIsOpen} title="Remove user" onConfirm={()=>removeUser()} />
        </>
    )
}


export default WhatsAppUserListItem