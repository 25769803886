import React from 'react'

import {
    Stack,
    Spinner,
    Tbody,
    Td,
    Tr,
    Table
} from '@chakra-ui/react';

import WhatsAppUserListItem from './WhatsAppUserListItem'

function WhatsAppUserList( props ) {

    const event = props.event
    const users = props.users

    const getDisplayName = ( item ) => {
        if ( item.name ) return item.name
        if ( item.short ) {
            if ( item.short === '__myself' ) return 'You'
            return item.short
        }
        return item.it
    }

    return (
        <Stack spacing={2}>
            { event.whatsAppGroup && !users && <Spinner />}
            { event.whatsAppGroup && users && (
                <Table>
                    <Tbody>
                        { users.sort( (a, b)=> (Number(a.isAdmin) < Number(b.isAdmin)) ? 1 : -1 ).map( item=> {
                            return (
                                <Tr key={item.jid}>
                                    <Td>{ getDisplayName(item) }</Td>
                                    <Td>
                                        <WhatsAppUserListItem key={item.jid} event={props.event} item={item} setGroupUsers={props.setGroupUsers} canAdminister={props.canAdminister} />
                                    </Td>
                                </Tr>
                            )
                        }) }
                    </Tbody>
                </Table>
            )}
        </Stack>
    )
}


export default WhatsAppUserList