import React, { useState, useEffect, useContext } from 'react'
import { Link as RouterLink, useParams, useHistory } from "react-router-dom"

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'

import { UserContext, userCanEdit } from '../../contexts/UserContext'
 
import Title from '../../components/typography/Title'
import EventDateTimeDisplay from './EventDateTimeDisplay'
import WhatsApp from './whatsapp/WhatsApp'
import Cameras from './cameras/Cameras'

import DeleteButton from '../../components/forms/buttons/DeleteButton'
import AlertDestructiveConfirm from '../../components/alerts/AlertDestructiveConfirm'

import {
    Container,
    Stack,
    Spinner,
    Link,
    Flex,
    Spacer,
} from '@chakra-ui/react';

import API from '../../backend/API'


dayjs.extend(utc)

const Event = () => {

    const [isAlertOpen, setAlertIsOpen] = React.useState(false)

    const { user, setUser } = useContext(UserContext)
    const { id } = useParams()
    const history = useHistory()

    const [event, setEvent] = useState(null)
    const [ interimObject, setInterimObject ] = useState(null)


    const deleteEvent = () => {
        API.Events_Delete( {user, id}, null, (json)=>{
            console.log('json', json)
            setEvent( null )
            history.push('/events')
        }, (error)=>{
            console.log('>>', `[${error}]`)
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }

    useEffect(() => {
        API.Events_Get( {user, id }, null, (json)=>{
            setEvent( json.item )
            console.log( json.item )
        }, (error)=>{
            console.log( error )
            setEvent( null )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }, [user, setUser, id])

    useEffect(() => {
        if ( interimObject ) {
            API.Events_Put( {user, id:interimObject.id, body:{
                name: interimObject.name,
                startsAt: interimObject.startsAt,
                endsAt: interimObject.endsAt,
                whatsAppGroup: interimObject.whatsAppGroup
                
            }}, null, (json)=>{
                setEvent( json.item )
                console.log( json.item )
            }, (error)=>{
                console.log( error )
                setEvent( null )
                if ( API.isRedirectionError(error) ) setUser(null)
            })
        }
    }, [user, setUser, interimObject])

    return (
        <Container maxW={'6xl'} mt={10}>
            { !event && (<Spinner />)}
            { event && (
                <Stack spacing={4}>
                    <Flex>
                        <Stack>
                            <Title editable={userCanEdit(user, event) || undefined} onSubmit={(value)=>setInterimObject( {...event, name:value} )}>{event.name}</Title>
                            { event && <Link as={RouterLink} to={`/users/${event.user_id}`}>{event.user_name}</Link> }
                        </Stack>
                        <Spacer />
                        { event && <DeleteButton onClick={()=>setAlertIsOpen(true)} /> }
                    </Flex>
                    
                    <Flex>
                        <EventDateTimeDisplay event={event} />
                    </Flex>
                    
                    <Flex>
                        <WhatsApp event={event} onInvite={(gid)=>setInterimObject( {...event, whatsAppGroup:gid} )} onDetatch={()=>setInterimObject( {...event, whatsAppGroup:null} )} />
                    </Flex>
                    
                    <Flex>
                        <Cameras event={event} />
                    </Flex>
                </Stack>
            )}

            <AlertDestructiveConfirm isOpen={isAlertOpen} setIsOpen={setAlertIsOpen} title="Confirm" onConfirm={()=>deleteEvent()} />
        </Container>
    )
};

export default Event