import React, { useRef, useState, useEffect } from 'react';
import RFB from '@novnc/novnc/core/rfb'

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import TextInput from '../../components/forms/textinput/TextInput'
import SubmitButton from '../../components/forms/buttons/SubmitButton'
import CloseButton from '../../components/forms/buttons/CloseButton'
import Value from '../../components/typography/Value'

import {
    Stack,
    Spacer,
    Center,
    Spinner,
    Flex,
    Box
} from '@chakra-ui/react';

export default function VNCViewer( props ) {

    const [rfb, setRfb] = useState(null);
    const screen = useRef(null);
    const [loading, setLoading] = useState(false);

    const [password, setPassword] = useState(null)
    const [name, setName] = useState(null)
    // const [autoConnect, setAutoConnect] = useState(false)

    const {
        url,
        viewOnly,
        focusOnClick,
        clipViewport,
        dragViewport,
        scaleViewport,
        resizeSession,
        showDotCursor,
        background,
        qualityLevel,
        compressionLevel,
    } = props;

    const disconnect = () => {
        if (!rfb) return
        rfb.disconnect();

        setRfb(null);
        setPassword(null)
    };

    const connect = () => {
        // disconnect();

        if (!screen.current) {
            return;
        }

        screen.current.innerHTML = '';

        const _rfb = new RFB(screen.current, url, { credentials:{password}})

        _rfb.viewOnly = viewOnly || false;
        _rfb.focusOnClick = focusOnClick || false;
        _rfb.clipViewport = clipViewport || false;
        _rfb.dragViewport = dragViewport || false;
        _rfb.resizeSession = resizeSession || false;
        _rfb.scaleViewport = scaleViewport || false;
        _rfb.showDotCursor = showDotCursor || false;
        _rfb.background = background || '';
        _rfb.qualityLevel = qualityLevel || 6;
        _rfb.compressionLevel = compressionLevel || 2;
        setRfb(_rfb);
        setLoading(true);

        _rfb.addEventListener('connect', () => {
            setLoading(false);
        });

        _rfb.addEventListener('disconnect', () => {
            // const retryDuration = 3000;
            // console.info(`Disconnected from remote VNC, retrying in ${retryDuration / 1000} seconds.`);
            console.info(`Disconnected from remote VNC`);
            // setTimeout(connect, retryDuration);
            // setLoading(true);
        });

        _rfb.addEventListener('credentialsrequired', () => {
            _rfb.sendCredentials({ password });
        });

        _rfb.addEventListener('desktopname', (e) => {
            setName( e.detail.name )
        });
    };



    const handleClick = () => {
        if (!rfb) return
        rfb.focus();
    }

    const handleMouseEnter = () => {
        if (document.activeElement && document.activeElement instanceof HTMLElement) document.activeElement.blur()
        handleClick();
    };

    const handleMouseLeave = () => {
        if (!rfb) return
        rfb.blur();
    };


    
    useEffect(() => {
        connect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password])


    return (<>
            { !rfb && (
                <Formik
                    initialValues={{
                        password: '',
                    }}
                    validationSchema={ Yup.object({
                        password: Yup.string()
                    }) }
                    onSubmit={(values) => setPassword( values.password )}
                    >
                    { formik => (
                        <Form id="input-form">
                            <Stack direction="row" spacing={4}>
                                <TextInput
                                    label="Password"
                                    name="password"
                                    type="password"
                                    autoComplete="off"
                                    isRequired
                                    variant="flushed"
                                />
                                <Stack>
                                    <Spacer />
                                    <SubmitButton size="sm" label="Connect" />
                                </Stack>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            )}

            { loading && <Center><Spinner /></Center>}

            { password && 
                <Stack spacing={2}>
                    <Flex>
                        <Value>{ name }</Value>
                        <Spacer />
                        <CloseButton size="xs" onClick={disconnect} />
                    </Flex>

                    <Box border={'1px grey solid'}>
                        <div
                            width={'100%'}
                            height={'100%'}
                            ref={screen}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        />
                    </Box>
                </Stack>}
        </>
    );
}