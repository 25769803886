import {
    ArrowForwardIcon,
    ArrowDownIcon
} from '@chakra-ui/icons'
 
const Divider = (props) => {
    return (
        <>
            <ArrowDownIcon align={'center'} w={'auto'} h={'10px'} mt={4} mb={4} border={'none'} display={{ base: 'block', sm: 'none' }} />
            <ArrowForwardIcon align={'center'} w={'10px'} h={'auto'} ml={2} mr={2} border={'none'} display={{ base: 'none', sm: 'block' }}/>
        </>
    )
};

export default Divider