import React, { useState, useEffect, useContext } from 'react'
import { useParams, useHistory } from "react-router-dom"

import { UserContext, userCanEdit } from '../../contexts/UserContext'
 
import Title from '../../components/typography/Title'
import Events from './events/Events'
import CameraDetails from './CameraDetails'
import CameraVNC from './CameraVNC'

import DeleteButton from '../../components/forms/buttons/DeleteButton'
import AlertDestructiveConfirm from '../../components/alerts/AlertDestructiveConfirm'

import {
    Container,
    Stack,
    Spinner,
    Flex,
    Spacer,
} from '@chakra-ui/react';

import API from '../../backend/API'


const Camera = () => {

    const [isAlertOpen, setAlertIsOpen] = useState(false)

    const { user, setUser } = useContext(UserContext)
    const { id } = useParams()
    const history = useHistory()

    const [camera, setCamera] = useState(null)
    const [ interimObject, setInterimObject ] = useState(null)


    const deleteCamera = () => {
        API.Cameras_Delete( {user, id}, null, (json)=>{
            console.log('json', json)
            setCamera( null )
            history.push('/cameras')
        }, (error)=>{
            console.log('>>', `[${error}]`)
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }

    useEffect(() => {
        API.Cameras_Get( {user, id }, null, (json)=>{
            setCamera( json.item )
            console.log( json.item )
        }, (error)=>{
            console.log( error )
            setCamera( null )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }, [user, setUser, id])

    useEffect(() => {
        if ( interimObject ) {
            API.Cameras_Put( {user, id:interimObject.id, body:{
                model: interimObject.model,
                profile: interimObject.profile,
                os: interimObject.os,
                
            }}, null, (json)=>{
                setCamera( json.item )
                console.log( json.item )
            }, (error)=>{
                console.log( error )
                setCamera( null )
                if ( API.isRedirectionError(error) ) setUser(null)
            })
        }
    }, [user, setUser, interimObject])

    return (
        <Container maxW={'6xl'} mt={10}>
            { !camera && (<Spinner />)}
            { camera && (
                <Stack spacing={4}>
                    <Flex>
                        <Title editable={userCanEdit(user, camera) || undefined} onSubmit={(value)=>setInterimObject( {...camera, name:value} )}>{camera.profile}</Title>
                        <Spacer />
                        { camera && <DeleteButton onClick={()=>setAlertIsOpen(true)} /> }
                    </Flex>

                    <Flex>
                        <CameraDetails camera={camera} editable={userCanEdit(user, camera) || undefined} onUpdate={(obj)=>setInterimObject(obj)} />
                    </Flex>

                    <Flex>
                        <Events camera={camera} />
                    </Flex>

                    <Flex>
                        <CameraVNC />
                    </Flex>
                </Stack>
            )}

            <AlertDestructiveConfirm isOpen={isAlertOpen} setIsOpen={setAlertIsOpen} title="Confirm" onConfirm={()=>deleteCamera()} />
        </Container>
    )
};

export default Camera