import React from 'react'

import Value from '../../components/typography/Value'

import {
    Box,
    Heading,
    Divider,
    Stack,
    useColorModeValue
} from '@chakra-ui/react';

const CameraDetails = ( props ) => {

    const editable = props.editable
    const camera = props.camera

    return (
        <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={4}
            >
            <Stack spacing={2}>
                <Heading fontSize="1xl">Details</Heading>
                
                <Divider/>

                <Value editable={editable} onSubmit={(value)=>props.onUpdate( {...camera, model:value} )}>{camera.model}</Value>
                <Value editable={editable} onSubmit={(value)=>props.onUpdate( {...camera, os:value} )}>{camera.os}</Value>
            </Stack>
        </Box>
    )
};

export default CameraDetails