import { useContext } from 'react'

import {
    Spinner
} from '@chakra-ui/react';

import API from '../../backend/API'

import { UserContext } from '../../contexts/UserContext'

const SignOutForm = ( props ) => {
    
    const { user } = useContext(UserContext)
    
    API.User_SignOut( {user}, null, props.onSuccess, (error)=>{
        console.log( error )
    } )

    return <Spinner />
};

export default SignOutForm