import { createContext } from 'react'

export const UserContext = createContext(null);

export const userIsAdmin = ( user ) => {
    return user && user.level > 1
}

export const userIsItemOwner = ( user, item ) => {
    return user && item.user_id === user.id
}

export const userCanEdit = ( user, item ) => {
    return userIsAdmin(user) || userIsItemOwner(user, item)
}