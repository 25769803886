import React from "react";

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import {
    Box,
    Stack,
    useColorModeValue,
} from '@chakra-ui/react';

import TextInput from '../../components/forms/textinput/TextInput'
import SubmitButton from '../../components/forms/buttons/SubmitButton'
import Checkbox from '../../components/forms/checkbox/Checkbox'
import AlertError from '../../components/alerts/AlertError'  

import API from '../../backend/API'

const SignUpForm = ( props ) => {

    const [isAlertOpen, setAlertIsOpen] = React.useState(false)
    const [alertMessage, setAlertMessage] = React.useState('')
 
    const submitForm = (values, { setSubmitting }) => {
        API.User_SignUp( values, setSubmitting, props.onSuccess, (error)=>{
            setAlertMessage( error )
            setAlertIsOpen( true )
        })
    }

    return (
        <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={4}>
                <Formik
                    initialValues={{
                        name: '',
                        email: '',
                        password: '',
                        acceptedTerms: false, // added for our checkbox
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().max(20, 'Must be 20 characters or less').required('Required'),
                        email: Yup.string().email('Invalid email address').required('Required'),
                        password: Yup.string().required('Required'),
                        acceptedTerms: Yup.boolean().required('Required').oneOf([true], 'You must accept the terms and conditions.')
                    })}
                    onSubmit={submitForm}
                    >
                    { formik => (
                        <Form>
                            <Stack spacing={4}>
                                <TextInput
                                    label="Name"
                                    name="name"
                                    type="text"
                                    placeholder="Jane"
                                    isRequired
                                />

                                <TextInput
                                    label="Email"
                                    name="email"
                                    type="email"
                                    placeholder="jane@formik.com"
                                    isRequired
                                />

                                <TextInput
                                    label="Password"
                                    name="password"
                                    type="password"
                                    placeholder="password"
                                    isRequired
                                />

                                <Checkbox isRequired name="acceptedTerms">I accept the terms and conditions</Checkbox>

                                <SubmitButton label="Sign up!" isLoading={ formik.isSubmitting } />
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Stack>

            <AlertError isOpen={isAlertOpen} setIsOpen={setAlertIsOpen} title="Error" message={alertMessage} />
        </Box>
    );
};

export default SignUpForm