import React, { useState, useEffect, useContext, useCallback } from 'react'

import {
    Heading,
    Stack,
    Box,
    Divider,
    Flex,
    useColorModeValue,
    Spacer,
    Spinner
} from '@chakra-ui/react';

import { UserContext } from '../../../contexts/UserContext'

import API from '../../../backend/API'

import EventList from './EventList'

function Events( props ) {

    const { user, setUser } = useContext(UserContext)

    const camera = props.camera

    const [ events, setEvents ] = useState(null)
    const getEvents = useCallback(() => {
        API.Cameras_Get_Events_List( {user, id:camera.id}, null, (json)=>{
            setEvents( json.items )
        }, (error)=>{
            setEvents( null )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }, [user, setUser, camera])
    useEffect(getEvents, [getEvents])

    const removeCamera = ( id ) => {
        console.log( 'remove ', id )
        // API.Events_Cameras_Remove( {user, id, camera_id:camera.id}, null, getEvents, (error)=>{
        //     if ( API.isRedirectionError(error) ) setUser(null)
        // })
    }

    return (
        <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={4}
            >
            <Stack spacing={2}>
                <Flex>
                    <Heading fontSize="1xl">Events</Heading>
                    <Spacer />
                    { !events && (<Spinner />)}
                </Flex>
                
                <Divider/>

                { events && <EventList
                    user={user}
                    camera={camera}
                    events={events}
                    onDeselect={(id)=>removeCamera(id)}
                /> }
            </Stack>
        </Box>
    )
}

export default Events