import React, { useEffect, useContext } from 'react'

import { UserContext } from '../../contexts/UserContext'

import {
    Button
} from '@chakra-ui/react';

import API from '../../backend/API'

const SettingsQueue = () => {

    const { user, setUser } = useContext(UserContext)
    const [submitting, setSubmitting] = React.useState(false)
    
    const [queue, setQueue] = React.useState(null)

    const getColourForQueuePurge = ( queue ) => {
        if ( !queue ) return 'red'
        if ( queue.attributes.ApproximateNumberOfMessages <= 0 ) return 'orange'
        return 'green'
    }

    const purgeQueue = () => {
        API.Queue_Purge( {user}, setSubmitting, (json)=>{
            setQueue( json.item )
        }, (error)=>{
            console.log( error )
            setQueue( null )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }

    useEffect(() => {
        API.Queue_List( {user}, setSubmitting, (json)=>{
            setQueue( json.item )
        }, (error)=>{
            console.log( error )
            setQueue( null )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }, [user, setUser])

    return (
        <Button
            bg={ `${getColourForQueuePurge(queue)}.400` }
            fontSize={'sm'}
            fontWeight={600}
            color={'white'}
            _hover={{
                bg: `${getColourForQueuePurge(queue)}.300`,
            }}
            isLoading={ submitting }
            onClick={purgeQueue}
            >
            Queue purge ({ queue ? queue.attributes.ApproximateNumberOfMessages : '-' })
        </Button>
    )
};

export default SettingsQueue