import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

function AdminRoute({ component: Component, ...restOfProps }) {
  
  const { user } = useContext( UserContext )
  var isAdminAuthenticated = !!user && user.level && user.level > 1

  return isAdminAuthenticated ? <Route {...restOfProps} render={(props) =><Component {...props} />} /> : <Redirect to="/signin" />
}

export default AdminRoute;