import { forwardRef } from 'react'
import { useField } from 'formik';
import dayjs from 'dayjs'


import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input
} from "@chakra-ui/react"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.css";

const DateInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta, helpers] = useField(props);
    const { setValue } = helpers

    const CustomInput = forwardRef(({ onClick }, ref) => {
        var value = field.value ? dayjs(field.value).utc().toLocaleString() : null
        return <Input type="text" onClick={onClick} ref={ref} autoComplete="off" variant={props.variant} {...field} value={value} defaultValue="" />
    });
    
    return (
        <FormControl id={props.id} isRequired={props.isRequired} isInvalid={(meta.touched && meta.error)}>
            <FormLabel fontSize="sm">{label}</FormLabel>
            <DatePicker showTimeSelect={props.showTimeSelect} selected={field.value || null} customInput={<CustomInput />} onChange={(value)=>{
                setValue( value )
            }}/>
            { props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    );
};

export default DateInput