import { useField } from 'formik';

import {
    FormControl,
    FormErrorMessage,
    FormHelperText,
    Checkbox as ChakraCheckbox
} from "@chakra-ui/react"

const Checkbox = ({ children, ...props }) => {
    // React treats radios and checkbox inputs differently other input types, select, and textarea.
    // Formik does this too! When you specify `type` to useField(), it will
    // return the correct bag of props for you -- a `checked` prop will be included
    // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
    const [field, meta] = useField({ ...props, type: 'checkbox' });

    return (
        <FormControl id={props.id} isRequired={props.isRequired} isInvalid={(meta.touched && meta.error)}>
            <ChakraCheckbox {...field}>{children}</ChakraCheckbox>
            { props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    );
};

export default Checkbox