import React, { useContext, useState } from 'react'

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import {
    Divider,
    Stack
} from '@chakra-ui/react';

import { UserContext } from '../../../contexts/UserContext'

import TextInput from '../../../components/forms/textinput/TextInput'
import SubmitButton from '../../../components/forms/buttons/SubmitButton'
import AlertError from '../../../components/alerts/AlertError' 

import API from '../../../backend/API'


const WhatsAppAcceptInviteForm = ( props ) => {

    const event = props.event
    const { user, setUser } = useContext(UserContext)

    const [isAlertOpen, setAlertIsOpen] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
 
    const submitForm = (values, { setSubmitting }) => {
        API.Events_WhatsApp_Invite_Post( {user, id:event.id, body:values}, setSubmitting, props.onSuccess, (error)=>{
            setAlertMessage( error )
            setAlertIsOpen( true )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }

    return (
        <>
            <Stack spacing={4}>
                <Formik
                    initialValues={{
                        invite_code: '',
                    }}
                    validationSchema={ Yup.object({
                        invite_code: Yup.string().required('Required'),
                    }) }
                    onSubmit={submitForm}
                >
                    { formik => (
                        <Form id="input-form">
                            <Stack spacing={4}>
                                <TextInput
                                    label="Invite code"
                                    name="invite_code"
                                    type="text"
                                    isRequired
                                    autoComplete="off"
                                    helperText="Find in WhatsApp Group Link"
                                />

                                <Divider />

                                <SubmitButton label="Join" size="sm" isLoading={ formik.isSubmitting }/>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Stack>

            <AlertError isOpen={isAlertOpen} setIsOpen={setAlertIsOpen} title="Error" message={alertMessage} />
        </>
    );
}

export default WhatsAppAcceptInviteForm