import { useField } from 'formik';

import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    InputGroup,
    InputRightElement
} from "@chakra-ui/react"

const TextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);

    return (
        <FormControl id={props.id} isRequired={props.isRequired} isInvalid={(meta.touched && meta.error)}>
            <FormLabel fontSize="sm" mb={0}>{label}</FormLabel>
            <InputGroup>
                <Input type={props.type} autoComplete={props.autoComplete} placeholder={props.placeholder} variant={props.variant} {...field} />
                { props.children && <InputRightElement width="4.5rem" p={0}>{props.children}</InputRightElement> }
            </InputGroup>
            { props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    );
};

export default TextInput