import React, { useContext } from 'react'

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PhoneNumber from 'awesome-phonenumber'

import {
    Divider,
    Stack
} from '@chakra-ui/react';

import { UserContext } from '../../../contexts/UserContext'

import TextInput from '../../../components/forms/textinput/TextInput'
import SubmitButton from '../../../components/forms/buttons/SubmitButton'
import AlertError from '../../../components/alerts/AlertError' 

import API from '../../../backend/API'

Yup.addMethod(Yup.string, "internationalMobile", function (errorMessage) {
    return this.test(`IntlMobile`, errorMessage, function (value) {
      var pn = PhoneNumber(value)
      return ( pn.isValid() && pn.isMobile() && pn.canBeInternationallyDialled() )
    });
});
  

const WhatsAppUserAddForm = ( props ) => {

    const event = props.event
    const { user, setUser } = useContext(UserContext)

    const [isAlertOpen, setAlertIsOpen] = React.useState(false)
    const [alertMessage, setAlertMessage] = React.useState('')
 
    const submitForm = (values, { setSubmitting }) => {
        //+447530607477
        var pn = PhoneNumber(values.phone)
        var body = {
            phone: pn.getNumber('e164'),
            region_code: pn.getRegionCode()
        }

        API.Events_WhatsApp_Users_Add( {user, id:event.id, body}, setSubmitting, props.onSuccess, (error)=>{
            setAlertMessage( error )
            setAlertIsOpen( true )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }

    return (
        <>
            <Stack spacing={4}>
                <Formik
                    initialValues={{
                        phone: '',
                    }}
                    validationSchema={ Yup.object({
                        phone: Yup.string().internationalMobile('Invalid phone number').required('Required'),
                    }) }
                    onSubmit={submitForm}
                    validateOnChange={false}
                    >
                    { formik => (
                        <Form id="input-form">
                            <Stack spacing={4}>
                                <TextInput
                                    label="Mobile"
                                    name="phone"
                                    type="phone"
                                    isRequired
                                    autoComplete="off"
                                    placeholder="phone"
                                    helperText="Include international dialing code (eg: +44)"
                                />

                                <Divider />
    
                                <SubmitButton label="Send" isLoading={ formik.isSubmitting }/>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Stack>

            <AlertError isOpen={isAlertOpen} setIsOpen={setAlertIsOpen} title="Error" message={alertMessage} />
        </>
    );
}

export default WhatsAppUserAddForm