import React, { useContext } from 'react';
import { Link, useHistory } from "react-router-dom"

import { UserContext } from '../../contexts/UserContext'

import {
    Box,
    Stack,
    Center
} from '@chakra-ui/react';

import Title from '../../components/typography/Title'
import SignInForm from './SignInForm'
 
const SignIn = () => {

    const { user, setUser } = useContext(UserContext)
    const history = useHistory()
  
    const onSuccess = ( data ) => {
        setUser( data.item )
        history.push("/");
    }

    return !user && (
        <Box px={0} maxW={"md"} m="auto" mt={["10px", "50px", "100px"]}>
            <Stack spacing={4}>
                <Title>Sign In</Title>

                <SignInForm onSuccess={onSuccess} />

                <Center w="100%">
                    <Link to="/signup">Create an account!</Link>
                </Center>
            </Stack>
        </Box>
    )
};

export default SignIn