import {
    Text,
    Editable,
    EditablePreview,
    EditableInput
} from '@chakra-ui/react';

import EditableControls from './EditableControls';
 
const Value = (props) => {

    if ( props.editable ) {
        return (
            <Editable defaultValue={props.children} onSubmit={props.onSubmit}>
                <Text as={EditablePreview} />
                <Text as={EditableInput} />
                <EditableControls />
            </Editable>
        )
    }
    return <Text {...props}>{props.children}</Text>
};

export default Value