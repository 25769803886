import React, { useContext } from 'react'
import { Link as RouterLink, } from "react-router-dom"

import {
    Stack,
    Spinner,
    Thead,
    Tbody,
    Th,
    Td,
    Tr,
    Table,
    Text,
    Link,
    Center,
    Avatar
} from '@chakra-ui/react';

import DeleteButton from '../../../components/forms/buttons/DeleteButton'
import DateTimeDisplay from '../../../components/typography/DateTimeDisplay'

import { UserContext, userCanEdit } from '../../../contexts/UserContext'

function EventList ( props ) {

    const { user } = useContext(UserContext)

    const camera = props.camera
    const events = props.events

    return (
        <Stack spacing={2}>
            <Table>
                <Thead>
                    <Tr>
                        <Th>Event</Th>
                        <Th>Starts At</Th>
                        <Th>Ends At</Th>
                        <Th><Center>Owner</Center></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    { !events && (
                        <Tr>
                            <Td colspan={5}><Center><Spinner /></Center></Td>
                        </Tr>
                    )}
                    { events && events.length===0 && (
                        <Tr>
                            <Td><Text color={"grey"} fontSize="xs">No events assigned</Text></Td>
                        </Tr>
                    )}
                    { events && events.map( item => (
                        <Tr key={item.id}>
                            <Td><Link as={RouterLink} to={`/events/${item.event_id}`}>{item.event_name}</Link></Td>
                            <Td><DateTimeDisplay date={ item.event_startsAt } /></Td>
                            <Td><DateTimeDisplay date={ item.event_endsAt } /></Td>
                            <Td>
                                <Stack direction="row" spacing={2}>
                                    <Center>
                                        <Avatar size="sm" as={RouterLink} to={`/users/${ item.event_user_id }`} />
                                        { false && userCanEdit(user, camera) && <DeleteButton onClick={()=>props.onDeselect(item.event_id)} />}
                                    </Center>
                                </Stack>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Stack>
    )
}


export default EventList