import {
    Heading,
    Editable,
    EditablePreview,
    EditableInput
} from '@chakra-ui/react';

import EditableControls from './EditableControls';
 
const Title = (props) => {

    if ( props.editable ) {
        return (
            <Editable defaultValue={props.children} onSubmit={props.onSubmit}>
                <Heading as={EditablePreview} fontSize="2xl" />
                <Heading as={EditableInput} fontSize="2xl" />
                <EditableControls />
            </Editable>
        )
    }
    return <Heading fontSize="2xl" {...props}>{props.children}</Heading>
};

export default Title