const API = {

    isRedirectionError:( error ) => {
        if ( !error ) return false
        if ( error === null ) return false
        
        var status = error.match(/^\[\d{3}\]/)
        switch ( status[0] ) {
            case '[401]':
            case '[403]':
                return true
            default:
                return false
        }
    },

    // URL builder
    BASE_PATH: 'https://dev.api.camerabooth.pics/camerabooth',
    VERSION: 'v1',

    getAPIUrl:( resource=null, extra=null ) => {
        return `${[ API.BASE_PATH, API.VERSION, resource ].filter( item => !!item ).join('/')}/${ extra && extra.length ? extra.join('/') : '' }`
    },


    // Base all-purpose request
    request:( input={url:null, user:null, body:{}, method:'GET', setSubmitting:null}, onSuccess=null, onError=null ) => {
        var headers = {
            'Content-Type': 'application/json',
        }
    
        if ( input.user ) headers['Authorization'] = `Bearer ${ input.user.auth_token }`
        if ( typeof input.setSubmitting === 'function' ) input.setSubmitting( true )
    
        fetch( input.url, {
            method: input.method,
            headers,
            body: JSON.stringify(input.body)
        })
        .then(res => {
            if ( res.status === 204 ) return {}
            return res.json()
        })
        .then(json => {
            if ( typeof input.setSubmitting === 'function' ) input.setSubmitting( false )
            if ( json.error ) return onError( json.error )
            if ( typeof onSuccess === 'function' ) return onSuccess( json )
        })
        .catch( error => {
            if ( typeof input.setSubmitting === 'function' ) input.setSubmitting( false )
            if ( typeof onError === 'function' ) return onError( error )
        })
    },


    // Interim helpers
    get:( input={url:null, user:null, body:{}, setSubmitting:null}, onSuccess=null, onError=null ) => {
        return API.request( {...input, method:'GET'}, onSuccess, onError )
    },

    post:( input={url:null, user:null, body:{}, setSubmitting:null}, onSuccess=null, onError=null ) => {
        return API.request( {...input, method:'POST'}, onSuccess, onError )
    },

    put:( input={url:null, user:null, body:{}, setSubmitting:null}, onSuccess=null, onError=null ) => {
        return API.request( {...input, method:'PUT'}, onSuccess, onError )
    },

    delete:( input={url:null, user:null, body:{}, setSubmitting:null}, onSuccess=null, onError=null ) => {
        return API.request( {...input, method:'DELETE'}, onSuccess, onError )
    },

    onResponse:( cb=null )=> {
        return ( res ) => {
            // If the onSuccess or onError is given and is a function, use it as callback
            if ( cb && typeof cb === 'function' ) return cb( res )
        }
    },

    
    // Individual calls
    User_SignIn:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.post( {url:API.getAPIUrl('users', ['login']), body:values, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    User_Authorize:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.post( {url:API.getAPIUrl('users', ['authorize']), body:values, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    User_SignUp:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.post( {url:API.getAPIUrl('users'), body:values, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    User_SignOut:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.post( {url:API.getAPIUrl('users', [values.user.id, 'logout']), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Users_List:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.get( {url:API.getAPIUrl('users'), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Users_Get:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.get( {url:API.getAPIUrl('users', [values.id]), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Users_Put:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.put( {url:API.getAPIUrl('users', [values.id]), user:values.user, body:values.body, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },

    
    Cameras_List:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.get( {url:API.getAPIUrl('cameras'), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Cameras_Get:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.get( {url:API.getAPIUrl('cameras', [values.id]), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Cameras_Get_Events_List:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.get( {url:API.getAPIUrl('cameras', [values.id, 'events']), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Cameras_Put:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.put( {url:API.getAPIUrl('cameras', [values.id]), user:values.user, body:values.body, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Cameras_Post:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.post( {url:API.getAPIUrl('cameras'), user:values.user, body:values.body, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Cameras_Delete:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.delete( {url:API.getAPIUrl('cameras', [values.id]), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },


    Settings_Get:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.get( { url:API.getAPIUrl('settings'), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Settings_Set:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.post( {url:API.getAPIUrl('settings'), user:values.user, body:{
            "settings": {
                "NOTIFICATIONS_ENABLED": values.notifications_enabled
            }
        }, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },


    Queue_Purge:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.post( {url:API.getAPIUrl('queues', ['purge']), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Queue_List:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.get( {url:API.getAPIUrl('queues'), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Queue_Subscribe:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.post( {url:API.getAPIUrl('queues', ['subscribe']), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Queue_Unsubscribe:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.post( {url:API.getAPIUrl('queues', ['unsubscribe']), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },


    Events_List:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.get( {url:API.getAPIUrl('events'), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Events_Get:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.get( {url:API.getAPIUrl('events', [values.id]), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Events_Put:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.put( {url:API.getAPIUrl('events', [values.id]), user:values.user, body:values.body, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Events_Post:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.post( {url:API.getAPIUrl('events'), user:values.user, body:values.body, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Events_Delete:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.delete( {url:API.getAPIUrl('events', [values.id]), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },


    Events_WhatsApp_Group:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.get( {url:API.getAPIUrl('events', [values.id, 'whatsapp']), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Events_WhatsApp_Group_Remove:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.delete( {url:API.getAPIUrl('events', [values.id, 'whatsapp']), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },

    Events_WhatsApp_Users_List:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.get( {url:API.getAPIUrl('events', [values.id, 'whatsapp', 'users']), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Events_WhatsApp_Users_Add:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.post( {url:API.getAPIUrl('events', [values.id, 'whatsapp', 'users']), user:values.user, body:values.body, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Events_WhatsApp_Users_Promote:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.put( {url:API.getAPIUrl('events', [values.id, 'whatsapp', 'users', values.jid, 'promote']), user:values.user, body:values.body, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Events_WhatsApp_Users_Demote:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.put( {url:API.getAPIUrl('events', [values.id, 'whatsapp', 'users', values.jid, 'demote']), user:values.user, body:values.body, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Events_WhatsApp_Users_Remove:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.delete( {url:API.getAPIUrl('events', [values.id, 'whatsapp', 'users', values.jid]), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },

    Events_WhatsApp_Invite_Get:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.get( {url:API.getAPIUrl('events', [values.id, 'whatsapp', 'invite']), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Events_WhatsApp_Invite_Post:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.post( {url:API.getAPIUrl('events', [values.id, 'whatsapp', 'invite']), user:values.user, body:values.body, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Events_WhatsApp_Invite_Delete:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.delete( {url:API.getAPIUrl('events', [values.id, 'whatsapp', 'invite']), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },

    Events_WhatsApp_Messages_Send:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.post( {url:API.getAPIUrl('events', [values.id, 'whatsapp', 'messages']), user:values.user, body:values.body, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },

    Events_Cameras_List:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.get( {url:API.getAPIUrl('events', [values.id, 'cameras']), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Events_Cameras_Assign:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.post( {url:API.getAPIUrl('events', [values.id, 'cameras']), user:values.user, body:values.body, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
    Events_Cameras_Remove:( values, setSubmitting=null, onSuccess=null, onError=null ) => {
        API.delete( {url:API.getAPIUrl('events', [values.id, 'cameras', values.camera_id]), user:values.user, setSubmitting}, API.onResponse(onSuccess), API.onResponse(onError))
    },
}

export default API