import React, { useEffect, useContext } from 'react'

import { UserContext } from '../../contexts/UserContext'

import {
    Button,
} from '@chakra-ui/react';
import {
    CheckIcon,
    CloseIcon
} from '@chakra-ui/icons'

import API from '../../backend/API'

const SettingsNotifications = ( props ) => {

    const { user, setUser } = useContext(UserContext)
    const [submitting, setSubmitting] = React.useState(false)
    
    const [settings, setSettings] = React.useState(null)

    const postSettings = ( value ) => {
        API.Settings_Set( {user, notifications_enabled:value}, setSubmitting, (json)=>{
            setSettings( json.item )
        }, (error)=>{
            console.log( error )
            setSettings( null )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }
    
    useEffect(() => {
        API.Settings_Get( {user}, setSubmitting, (json)=>{
            setSettings( json.item )
        }, (error)=>{
            console.log( error )
            setSettings( null )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }, [user, setUser])

    return (
        <Button
            bg={settings && settings.NOTIFICATIONS_ENABLED ? 'green.400' : 'red.400'}
            fontSize={'sm'}
            fontWeight={600}
            color={'white'}
            _hover={{
                bg: settings && settings.NOTIFICATIONS_ENABLED ? 'green.300' : 'red.300',
            }}
            disabled={!settings}
            isLoading={ submitting }
            onClick={()=>postSettings(!settings.NOTIFICATIONS_ENABLED)}
            >
            Notifications &nbsp; { settings && settings.NOTIFICATIONS_ENABLED ? <CheckIcon /> : <CloseIcon />}
        </Button>
    )
};

export default SettingsNotifications