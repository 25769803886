import React from 'react'
import { Link as RouterLink } from 'react-router-dom';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import {
    Box,
    Stack,
    Link,
    Checkbox,
    useColorModeValue
} from '@chakra-ui/react';

import TextInput from '../../components/forms/textinput/TextInput'
import SubmitButton from '../../components/forms/buttons/SubmitButton'
import AlertError from '../../components/alerts/AlertError' 

import API from '../../backend/API'

const SignInForm = ( props ) => {

    const [isAlertOpen, setAlertIsOpen] = React.useState(false)
    const [alertMessage, setAlertMessage] = React.useState('')
 
    const submitForm = (values, { setSubmitting }) => {
        API.User_SignIn( values, setSubmitting, props.onSuccess, (error)=>{
            setAlertMessage( error )
            setAlertIsOpen( true )
        })
    }

    return (
        <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={4}>
                <Formik
                    initialValues={{
                        email: '',
                        password: ''
                    }}
                    validationSchema={ Yup.object({
                        email: Yup.string().email('Invalid email address').required('Required'),
                        password: Yup.string().required('Required'),
                    }) }
                    onSubmit={submitForm}
                    >
                    { formik => (
                        <Form>
                            <Stack spacing={4}>
                                <TextInput
                                    label="Email Address"
                                    name="email"
                                    type="email"
                                    placeholder="email@domain.com"
                                    isRequired
                                />
                    
                                <TextInput
                                    label="Password"
                                    name="password"
                                    type="password"
                                    placeholder="password"
                                    isRequired
                                />
    
                                <Stack
                                    direction={{ base: 'column', sm: 'row' }}
                                    align={'start'}
                                    justify={'space-between'}>
                                    <Checkbox>Remember me</Checkbox>
                                    <Link as={RouterLink} color={'blue.400'} to="/forgot">Forgot password?</Link>
                                </Stack>
    
                                <SubmitButton label="Sign in!" isLoading={ formik.isSubmitting }/>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Stack>

            <AlertError isOpen={isAlertOpen} setIsOpen={setAlertIsOpen} title="Error" message={alertMessage} />
        </Box>
    );
}

export default SignInForm