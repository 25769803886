import React, { useState, useEffect, useContext, useCallback } from 'react'

import {
    Heading,
    Stack,
    Box,
    Divider,
    Flex,
    useColorModeValue,
    Spacer,
    Spinner
} from '@chakra-ui/react';

import AlertDestructiveConfirm from '../../../components/alerts/AlertDestructiveConfirm'

import ChatButton from '../../../components/forms/buttons/ChatButton'
import ActionButton from '../../../components/forms/buttons/ActionButton'
import DeleteButton from '../../../components/forms/buttons/DeleteButton'
import ModalForm from '../../../components/modal/ModalForm'

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'

import { UserContext } from '../../../contexts/UserContext'

import API from '../../../backend/API'

import WhatsAppUserList from './WhatsAppUserList'
import WhatsAppSendMessageForm from './WhatsAppSendMessageForm'
import WhatsAppAcceptInviteForm from './WhatsAppAcceptInviteForm'
import WhatsAppUserAddForm from './WhatsAppUserAddForm'


dayjs.extend(utc)

function WhatsApp( props ) {

    const { user, setUser } = useContext(UserContext)

    const event = props.event
    const onInvite = props.onInvite
    const onDetatch = props.onDetatch

    const [isChatModalOpen, setChatModalIsOpen] = useState(false)
    const [isUserAddModalOpen, setUserAddModalIsOpen] = useState(false)
    const [isDetatchAlertIsOpen, setDetatchAlertIsOpen] = useState(false)

    
    const getSelfParticipant = () => {
        return groupUsers ? groupUsers.filter( item => item.short === '__myself' ).pop() : null
    }

    const getHeading = ( group ) => {
        if ( !group ) return <Spinner />
        if ( group.subject ) return group.subject 
        return 'WhatsApp'
    }


    const [ group, setGroup ] = useState(null)
    const getGroup = useCallback(() => {
        if ( !event.whatsAppGroup ) return 
        API.Events_WhatsApp_Group( {user, id:event.id}, null, (json)=>{
            console.log( json.item )
            setGroup( json.item )
        }, (error)=>{
            setGroup( null )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }, [user, setUser, event])
    useEffect(getGroup, [getGroup])

    // const deleteGroup = () => {
    //     if ( !event.whatsAppGroup ) return 
    //     API.Events_WhatsApp_Group_Remove( {user, id:event.id}, null, (json)=>{
    //         setGroup( null )
    //     }, (error)=>{
    //         if ( API.isRedirectionError(error) ) setUser(null)
    //     })
    // }
    const detatchGroup = () => {
        var myself = getSelfParticipant()
        if ( myself ) {
            setDetatchAlertIsOpen( false )
            setGroupUsers( null )
            setGroup( null )
            
            API.Events_WhatsApp_Users_Remove( {user, id:event.id, jid:myself.jid}, null, (json)=>{
                onDetatch()
                
            }, (error)=>{
                if ( API.isRedirectionError(error) ) setUser(null)
            })
        }
    }



    const [ groupUsers, setGroupUsers ] = useState(null)
    const getGroupUsers = useCallback(() => {
        if ( !event.whatsAppGroup ) return 
        API.Events_WhatsApp_Users_List( {user, id:event.id}, null, (json)=>{
            console.log( json.items )
            setGroupUsers( json.items )
        }, (error)=>{
            setGroupUsers( null )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }, [user, setUser, event])
    useEffect(getGroupUsers, [getGroupUsers])

    return (
        <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={4}
            >
            <Stack spacing={2}>
                <Flex>
                    { !event.whatsAppGroup && <Heading fontSize="1xl">WhatsApp</Heading> }
                    { event.whatsAppGroup && <Heading fontSize="1xl">{ getHeading(group) }</Heading> }
                </Flex>
                
                <Divider/>

                { !event.whatsAppGroup && <WhatsAppAcceptInviteForm event={event} onSuccess={(json)=>onInvite( json.item.id )} /> }
                { event.whatsAppGroup && group && <WhatsAppUserList
                    user={user}
                    event={event}
                    users={groupUsers}
                    setGroupUsers={setGroupUsers}
                    canAdminister={getSelfParticipant() ? getSelfParticipant().isAdmin : false }
                /> }
                
                { event.whatsAppGroup && group &&
                    <Flex>
                        <Stack direction={"row"} spacing={2} shouldWrapChildren={true}>
                            <ChatButton size="sm" onClick={()=>setChatModalIsOpen(true)} />
                            <ActionButton size="sm" label="Invite" onClick={()=>setUserAddModalIsOpen(true)} />
                        </Stack>
                        <Spacer />
                        <DeleteButton size="sm" onClick={()=>setDetatchAlertIsOpen(true)} />
                    </Flex>
                }
            </Stack>

            
            <ModalForm isOpen={isChatModalOpen} onClose={()=>setChatModalIsOpen(false)} title="Send Message">
                <WhatsAppSendMessageForm event={event} onSuccess={(json)=>{
                    console.log( json )
                    setChatModalIsOpen(false)
                }} />
            </ModalForm>

            <ModalForm isOpen={isUserAddModalOpen} onClose={()=>setUserAddModalIsOpen(false)} title="Invite User">
                <WhatsAppUserAddForm event={event} onSuccess={(json)=>{
                    console.log( json )
                    setUserAddModalIsOpen(false)
                    getGroupUsers()
                }} />
            </ModalForm>

            <AlertDestructiveConfirm isOpen={isDetatchAlertIsOpen} setIsOpen={setDetatchAlertIsOpen} title="Detatch from WhatsApp?" onConfirm={()=>detatchGroup()} />
        </Box>
    )
}

export default WhatsApp