import React from 'react';
import {
    Button
} from '@chakra-ui/react';
import {
    CloseIcon
} from '@chakra-ui/icons'

const ViewButton = ({ label, ...props }) => {
    return <Button
        bg={'red.400'}
        color={'white'}
        _hover={{
            bg: 'red.500',
        }}
        {...props}
        ><CloseIcon /></Button>
};

export default ViewButton