import React, { useState, useEffect, useContext, useCallback } from 'react'

import {
    Heading,
    Stack,
    Box,
    Divider,
    Flex,
    useColorModeValue,
    Spacer,
    Spinner
} from '@chakra-ui/react';

import ActionButton from '../../../components/forms/buttons/ActionButton'

import ModalForm from '../../../components/modal/ModalForm'

import { UserContext } from '../../../contexts/UserContext'

import API from '../../../backend/API'

import CameraList from './CameraList'
import CameraSelectionForm from './CameraSelectionForm'

function Cameras( props ) {

    const { user, setUser } = useContext(UserContext)
    const [isModalOpen, setModalIsOpen] = useState(false)

    const event = props.event

    const [ cameras, setCameras ] = useState(null)
    const getCameras = useCallback(() => {
        API.Events_Cameras_List( {user, id:event.id}, null, (json)=>{
            setCameras( json.items )
        }, (error)=>{
            setCameras( null )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }, [user, setUser, event])
    useEffect(getCameras, [getCameras])

    const assignCamera = ( id ) => {
        API.Events_Cameras_Assign( {user, id:event.id, body:{camera_id:id}}, null, getCameras, (error)=>{
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }
    const removeCamera = ( id ) => {
        API.Events_Cameras_Remove( {user, id:event.id, camera_id:id}, null, getCameras, (error)=>{
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }

    return (
        <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={4}
            >
            <Stack spacing={2}>
                <Flex>
                    <Heading fontSize="1xl">Cameras</Heading>
                    <Spacer />
                    { !cameras && (<Spinner />)}
                </Flex>
                
                <Divider/>

                { cameras && <CameraList
                    user={user}
                    event={event}
                    cameras={cameras}
                    onDeselect={(id)=>removeCamera(id)}
                /> }

                { cameras && <ActionButton label="Add Camera" size="sm" onClick={()=>setModalIsOpen(true)} /> }
            </Stack>

            <ModalForm isOpen={isModalOpen} onClose={()=>setModalIsOpen(false)} title="Add Camera">
                <CameraSelectionForm selected={cameras} onSelect={(id)=>assignCamera(id)} onDeselect={(id)=>removeCamera(id)} />
            </ModalForm>
        </Box>
    )
}

export default Cameras