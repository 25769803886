import React from 'react';
import {
    Button
} from '@chakra-ui/react';
import {
    ChatIcon
} from '@chakra-ui/icons'

const ChatButton = ( props ) => {
    return <Button
        size="sm"
        bg={'blue.400'}
        color={'white'}
        _hover={{
            bg: 'blue.500',
        }}
        {...props}
        aria-label="Chat"><ChatIcon /></Button>
};

export default ChatButton