import React from 'react';

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    Flex,
    Spacer,
  } from "@chakra-ui/react"

export default function AlertDestructiveConfirm( props ) {

    // props.isOpen, props.setIsOpen, props.title, props.message

    const onClose = () => props.setIsOpen(false)
    const cancelRef = React.useRef()
  
    return (
        <AlertDialog
            isOpen={props.isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {props.title || 'Error'}
                    </AlertDialogHeader>
        
                    <AlertDialogBody>
                        {props.message || 'Are you sure?'}
                    </AlertDialogBody>
        
                    <AlertDialogFooter>
                        <Flex>
                            <Button ref={cancelRef} size="sm" onClick={onClose} ml={3}>Cancel</Button>
                            <Spacer />
                            <Button size="sm" colorScheme="red" onClick={props.onConfirm} ml={3}>Confirm</Button>
                        </Flex>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}