import React, { useState, useEffect, useContext } from 'react'
import { useParams } from "react-router-dom"

import { UserContext, userCanEdit, userIsAdmin } from '../../contexts/UserContext'
 
import Title from '../../components/typography/Title'

import {
    Container,
    Stack,
    Spinner,
    FormControl,
    FormLabel,
    Switch
} from '@chakra-ui/react';

import API from '../../backend/API'

const User = () => {

    const { user, setUser } = useContext(UserContext)
    const { id } = useParams()

    const [ userProfile, setUserProfile] = useState(null)
    const [ interimObject, setInterimObject ] = useState(null)

    useEffect(() => {
        API.Users_Get( {user, id:(id==='me' ? user.id : id) }, null, (json)=>{
            setUserProfile( json.item )
            console.log( json.item )
        }, (error)=>{
            console.log( error )
            setUserProfile( null )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }, [user, setUser, id])

    useEffect(() => {
        if ( interimObject ) {
            API.Users_Put( {user, id:interimObject.id, body:{
                name: interimObject.name,
                level: interimObject.level
            }}, null, (json)=>{
                setUserProfile( json.item )
                console.log( json.item )
            }, (error)=>{
                console.log( error )
                setUserProfile( null )
                if ( API.isRedirectionError(error) ) setUser(null)
            })
        }
    }, [user, setUser, interimObject])

    return (
        <Container maxW={'6xl'} mt={10}>
            { !userProfile && (<Spinner />)}
            { userProfile && (
                <Stack spacing={4}>
                    <Title editable={userCanEdit(user, userProfile) || undefined} onSubmit={(value)=>setInterimObject( {...userProfile, name:value} )}>{userProfile.name}</Title>

                    <p>[Stats]</p>

                    { userIsAdmin(user) && (
                        <FormControl display="flex" alignItems="center">
                            <FormLabel htmlFor="isAdmin" mb="0">
                                Admin
                            </FormLabel>
                            <Switch id="isAdmin" defaultChecked={userProfile.level>1} onChange={(e)=>{
                                setInterimObject( {...userProfile, level:(e.target.checked ? 2 : 1)} )
                            }}/>
                        </FormControl>
                    )}
                </Stack>
            )}
        </Container>
    )
};

export default User