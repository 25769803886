import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  
  const { user } = useContext( UserContext )
  var isUserAuthenticated = !!user

  return isUserAuthenticated ? <Route {...restOfProps} render={(props) =><Component {...props} />} /> : <Redirect to="/signin" />
}

export default ProtectedRoute;