import React from 'react';
import {
    Button
} from '@chakra-ui/react';
import {
    MinusIcon
} from '@chakra-ui/icons'

const DemoteButton = ( props ) => {
    return <Button
        size="sm"
        bg={'red.400'}
        color={'white'}
        _hover={{
            bg: 'red.500',
        }}
        {...props}
        aria-label="Demote"><MinusIcon /></Button>
};

export default DemoteButton