import React from 'react'

import {
    Button
} from '@chakra-ui/react';

const SettingsLambda = () => {

    return (
        <Button
            fontSize={'sm'}
            fontWeight={600}
            >
            Lambda
        </Button>
    )
};

export default SettingsLambda