import {
    HStack,
    Stack,
    Spacer,
    Box,
    Text
} from '@chakra-ui/react';
import {
    CalendarIcon,
    TimeIcon
} from '@chakra-ui/icons';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

function DateTimeDisplay( props ) {
    return (
        <Box
            p={4}
            >
            <Stack spacing={2}>
                <HStack>
                    {!props.rtl && <CalendarIcon /> }
                    {props.rtl && <Spacer /> }
                    <Text >{ dayjs(props.date).utc().format('DD/MM/YYYY') }</Text>
                    {props.rtl && <CalendarIcon /> }
                </HStack>
                <HStack>
                    {!props.rtl && <TimeIcon /> }
                    {props.rtl && <Spacer /> }
                    <Text >{ dayjs(props.date).utc().format('HH:mm') }</Text>
                    {props.rtl && <TimeIcon /> }
                </HStack>
            </Stack>
        </Box>
    )
}

export default DateTimeDisplay