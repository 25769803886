import React, { useContext } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import {
    Stack,
    Spinner,
    Tbody,
    Td,
    Tr,
    Table,
    Text,
    Link
} from '@chakra-ui/react';

import DeleteButton from '../../../components/forms/buttons/DeleteButton'

import { UserContext, userCanEdit } from '../../../contexts/UserContext'

function CameraList ( props ) {

    const { user } = useContext(UserContext)
    const cameras = props.cameras

    return (
        <Stack spacing={2}>
            { !cameras && <Spinner />}
            { cameras && (
                <Table>
                    <Tbody>
                        { cameras.length===0 && (
                            <Tr>
                                <Td><Text color={"grey"} fontSize="xs">No cameras set</Text></Td>
                            </Tr>
                        )}
                        { cameras.map( item => (
                            <Tr key={item.id}>
                                <Td><Link as={RouterLink} to={`/cameras/${item.camera_id}`}>{ item.camera_profile }</Link></Td>
                                <Td>{ userCanEdit(user, item) && <DeleteButton onClick={()=>props.onDeselect(item.id)} />}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}
        </Stack>
    )
}


export default CameraList