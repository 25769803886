import React, { useContext } from 'react'
import { Link as RouterLink } from 'react-router-dom';

import { UserContext } from '../../contexts/UserContext'

import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    Menu,
    MenuButton,
    Avatar,
    MenuList,
    MenuItem,
    MenuDivider
} from '@chakra-ui/react';
import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon,
} from '@chakra-ui/icons';
  
export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();
  
  const { user } = useContext(UserContext)

  const allowAuthentication = false
  
  return (
    <Box>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}>
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
      </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Text
            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
            fontFamily={'heading'}
            color={useColorModeValue('gray.800', 'white')}>
            <RouterLink to="/">Camerabooth</RouterLink>
          </Text>

          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>
  
        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}>

          { allowAuthentication && !user &&
            <Button
              bg={'white'}
              fontSize={'sm'}
              fontWeight={400}
              as={RouterLink}
              to="/signin">
              Sign In
            </Button>
          }
          { allowAuthentication && !user && 
            <Button
              bg={'pink.400'}
              display={{ base: 'none', md: 'inline-flex' }}
              fontSize={'sm'}
              fontWeight={600}
              color={'white'}
              _hover={{
                bg: 'pink.300',
              }}
              as={RouterLink}
              to="/signup">
              Sign Up
            </Button>
          }
          { allowAuthentication && user &&
            <Flex alignItems={'center'}>
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={'full'}
                  variant={'link'}
                  cursor={'pointer'}
                  minW={0}>
                  <Avatar
                    size={'sm'}
                    src={
                      'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                    }
                  />
                </MenuButton>
                <MenuList>
                  <MenuItem><RouterLink to="/users/me">Profile</RouterLink></MenuItem>
                  { user && user.level > 1 && <MenuItem><RouterLink to="/settings">Settings</RouterLink></MenuItem> }
                  <MenuDivider />
                  <MenuItem><RouterLink to="/signout">Sign out</RouterLink></MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          }
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}
  
const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');
  
  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.filter( NavigationFilter ).map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                p={2}
                fontSize={'sm'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                    textDecoration: 'none',
                    color: linkHoverColor,
                }}
                as={RouterLink}
                to={navItem.href}
                target={navItem.target || '_self'}
                >{navItem.label}</Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                  <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};
  
const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
          transition={'all .3s ease'}
          _groupHover={{ color: 'pink.400' }}
          fontWeight={500}>
          {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};
  
const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}>
      {NAV_ITEMS.filter( NavigationFilter ).map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};
  
const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={RouterLink}
        to={href}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
      }}>
      <Text
        fontWeight={600}
        color={useColorModeValue('gray.600', 'gray.200')}>
        {label}
      </Text>
      {children && (
        <Icon
          as={ChevronDownIcon}
          transition={'all .25s ease-in-out'}
          transform={isOpen ? 'rotate(180deg)' : ''}
          w={6}
          h={6}
        />
      )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children && 
            children.map((child) => (
              <Link key={child.label} py={2} as={RouterLink} to={child.href}>
                {child.label}
              </Link>
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NavigationFilter = ( item ) => {
  
  const { user } = useContext(UserContext)
  
  if ( item.minLevel === 0 ) return true
  if ( !user ) return false
  if ( user.level < item.minLevel ) return false

  return true
}
  
const NAV_ITEMS = [
    // {
    //   label: 'Inspiration',
    //   children: [
    //     {
    //       label: 'Explore Design Work',
    //       subLabel: 'Trending Design to inspire you',
    //       href: '#',
    //     },
    //     {
    //       label: 'New & Noteworthy',
    //       subLabel: 'Up-and-coming Designers',
    //       href: '#',
    //     },
    //   ],
    // },
    // {
    //   label: 'Find Work',
    //   children: [
    //     {
    //       label: 'Job Board',
    //       subLabel: 'Find your dream design job',
    //       href: '#',
    //     },
    //     {
    //       label: 'Freelance Projects',
    //       subLabel: 'An exclusive list for contract work',
    //       href: '#',
    //     },
    //   ],
    // },
  {
    label: 'Privacy',
    href: '/privacy',
    minLevel: 0
  },
  {
    label: 'Survey',
    href: { pathname: "https://docs.google.com/forms/d/e/1FAIpQLSeVGOOVyPydEA1TsZFWxHdbLPB_AQpvxUrTq4h4sozedV_xAw/viewform" },
    minLevel: 0,
    target:"_blank"
  },
  {
    label: 'Events',
    href: '/events',
    minLevel: 1
  },
  {
    label: 'Cameras',
    href: '/cameras',
    minLevel: 2
  },
  {
    label: 'Users',
    href: '/users',
    minLevel: 2
  },
];