import React, { useContext, useState, useCallback, useEffect } from 'react'

import { UserContext } from '../../../contexts/UserContext'

import {
    Stack,
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    Th,
    Switch,
    Center,
    Spinner,
} from '@chakra-ui/react';

import API from '../../../backend/API'

const CameraSelectionForm = ( props ) => {

    const { user, setUser } = useContext(UserContext)
    const selected = props.selected

    const [cameras, setCameras] = useState(null)
    const getAllCameras = useCallback(() => {
        API.Cameras_List( {user}, null, (json)=>{
            setCameras( json.items )
        }, (error)=>{
            setCameras( null )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }, [user, setUser])
    useEffect(getAllCameras, [getAllCameras])

    const isCameraSelected = (camera) => {
        return selected.filter( item => item.camera_id===camera.id ).length
    }

    return (
        <>
            <Stack spacing={4}>
                { !cameras && (
                    <Center>
                        <Spinner />
                    </Center>
                )}
                { cameras && (
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Profile</Th>
                                <Th><Center>Assigned</Center></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            { cameras.map( item => (
                                <Tr key={item.id}>
                                    <Td>{item.profile}</Td>
                                    <Td>
                                        <Center>
                                            <Switch id="isAdmin" defaultChecked={isCameraSelected(item)} onChange={(e)=>{
                                                if ( e.target.checked ) {
                                                    props.onSelect( item.id )
                                                } else {
                                                    props.onDeselect( item.id )
                                                }
                                            }}/>
                                        </Center>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                )}
            </Stack>
        </>
    );
}

export default CameraSelectionForm