import React, { useEffect, useContext } from 'react'
import { Link as RouterLink } from 'react-router-dom';

import { UserContext } from '../../contexts/UserContext'

import {
    Button
} from '@chakra-ui/react';

import API from '../../backend/API'

const SettingsCamera = () => {

    const { user, setUser } = useContext(UserContext)
    const [submitting, setSubmitting] = React.useState(false)

    const [cameras, setCameras] = React.useState(null)

    const getColourForCameras = ( cameras ) => {
        if ( !cameras ) return 'red'
        return 'green'
    }

    useEffect(() => {
        API.Cameras_List( {user}, setSubmitting, (json)=>{
            setCameras( json.items )
        }, (error)=>{
            console.log( error )
            setCameras( null )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }, [user, setUser])

    return (
        <Button
            bg={ `${getColourForCameras(cameras)}.400` }
            fontSize={'sm'}
            fontWeight={600}
            color={'white'}
            _hover={{
                bg: `${getColourForCameras(cameras)}.300`,
            }}
            isLoading={ submitting }
            as={RouterLink}
            to="/cameras"
            >
            Cameras ({ cameras ? cameras.length : '0' })
        </Button>
    )
};

export default SettingsCamera