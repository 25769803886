import React, { useEffect, useContext } from 'react'
import { Link as RouterLink } from 'react-router-dom';

import { UserContext, userIsAdmin } from '../../contexts/UserContext'
 
import Title from '../../components/typography/Title'

import {
    Container,
    Stack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Spinner,
    Flex,
    Link,
    Spacer,
} from '@chakra-ui/react';
import {
    CheckIcon,
    CloseIcon,
} from '@chakra-ui/icons'

import API from '../../backend/API'

const Users = () => {

    const { user, setUser } = useContext(UserContext)

    const [users, setUsers] = React.useState(null)

    useEffect(() => {
        API.Users_List( {user}, null, (json)=>{
            setUsers( json.items )
            console.log( json.items )
        }, (error)=>{
            console.log( error )
            setUsers( null )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }, [user, setUser])

    return (
        <Container maxW={'6xl'} mt={10}>
            <Stack spacing={4}>
                <Flex>
                    <Title>Users</Title>
                    <Spacer />
                    { !users && (<Spinner />)}
                </Flex>
                { users && (
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Name</Th>
                                <Th>Email</Th>
                                <Th>Joined</Th>
                                <Th>Admin</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                users.map( item => {
                                    return (
                                        <Tr key={item.id}>
                                            <Td><Link as={RouterLink} to={`users/${item.id}`} >{item.name}</Link></Td>
                                            <Td>{item.email}</Td>
                                            <Td>{item.createdAt}</Td>
                                            <Td>{ userIsAdmin(item) ? <CheckIcon /> : <CloseIcon /> }</Td>
                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </Table>
                )}
            </Stack>
        </Container>
    )
};

export default Users