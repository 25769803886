import React from 'react'

import Title from '../../components/typography/Title'
import Header from '../../components/typography/Header'
import Divider from '../../components/typography/Divider'

import SettingsCamera from './SettingsCamera';
import SettingsNotifications from './SettingsNotifications';
import SettingsQueue from './SettingsQueue';
import SettingsSubscription from './SettingsSubscription';
import SettingsLambda from './SettingsLambda';

import {
    Stack,
    Box,
    Container
} from '@chakra-ui/react';

const Settings = () => {

    var params = { w:'full' }
    const ordering = [
        <SettingsCamera         {...params} />,
        <SettingsNotifications  {...params} />,
        <SettingsQueue          {...params} />,
        <SettingsSubscription   {...params} />,
        <SettingsLambda         {...params} />
    ]

    return (
        <Container maxW={'6xl'} mt={10}>
            <Stack spacing={4}>
                <Title>Settings</Title>
                
                <Header>Pipeline</Header>
                <Stack direction={["column", "row"]} spacing="24px" divider={<Divider />}>
                    { ordering.map( (item, index) => (
                        <Box key={`item-${index}`} align={'center'} w={'full'} h={'auto'}>{ item }</Box>
                    ))}
                </Stack>
            </Stack>
        </Container>
    )
};

export default Settings