import React, { useContext, useState } from 'react'

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'

import { UserContext } from '../../contexts/UserContext'

import {
    Divider,
    Stack
} from '@chakra-ui/react';

import TextInput from '../../components/forms/textinput/TextInput'
import SubmitButton from '../../components/forms/buttons/SubmitButton'
import AlertError from '../../components/alerts/AlertError' 

import API from '../../backend/API'


dayjs.extend(utc)

const CameraForm = ( props ) => {

    const { user, setUser } = useContext(UserContext)

    const [isAlertOpen, setAlertIsOpen] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
 
    const submitForm = (values, { setSubmitting }) => {
        API.Cameras_Post( {user, body:values}, setSubmitting, props.onSuccess, (error)=>{
            setAlertMessage( error )
            setAlertIsOpen( true )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }

    return (
        <>
            <Stack spacing={4}>
                <Formik
                    initialValues={{
                        name: '',
                        model: '',
                        os: '',
                    }}
                    validationSchema={ Yup.object({
                        name: Yup.string().required('Required'),
                        model: Yup.date().required('Required'),
                        os: Yup.date().required('Required'),
                    }) }
                    onSubmit={submitForm}
                    >
                    { formik => (
                        <Form id="input-form">
                            <Stack spacing={4}>
                                <TextInput
                                    label="Event name"
                                    name="name"
                                    type="text"
                                    placeholder="Birthday party"
                                    isRequired
                                    autoComplete="off"
                                    variant="flushed"
                                />

                                <TextInput
                                    label="Model"
                                    name="model"
                                    type="text"
                                    placeholder="Raspberry Pi"
                                    isRequired
                                    autoComplete="off"
                                    variant="flushed"
                                />

                                <TextInput
                                    label="Operating System"
                                    name="os"
                                    type="text"
                                    placeholder="Raspbian"
                                    isRequired
                                    autoComplete="off"
                                    variant="flushed"
                                />

                                <Divider />
    
                                <SubmitButton label="Create" isLoading={ formik.isSubmitting }/>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Stack>

            <AlertError isOpen={isAlertOpen} setIsOpen={setAlertIsOpen} title="Error" message={alertMessage} />
        </>
    );
}

export default CameraForm