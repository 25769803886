import React, { useEffect, useState, useContext, useCallback } from 'react'
import { Link as RouterLink, useHistory } from "react-router-dom"

import { UserContext } from '../../contexts/UserContext'
 
import Title from '../../components/typography/Title'
import AddButton from '../../components/forms/buttons/AddButton'
import ModalForm from '../../components/modal/ModalForm'
import EventForm from './EventForm'

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'

import {
    Container,
    Stack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Spinner,
    Flex,
    Spacer,
    Link,
    Center,
} from '@chakra-ui/react';
import {
    CheckIcon,
    CloseIcon
} from '@chakra-ui/icons'

import API from '../../backend/API'


dayjs.extend(utc)

const Events = () => {

    const { user, setUser } = useContext(UserContext)
    const history = useHistory()

    const [events, setEvents] = useState(null)
    const [isModalOpen, setModalIsOpen] = useState(false)

    const listEvents = useCallback(() => {
        API.Events_List( {user}, null, (json)=>{
            setEvents( json.items )
            setModalIsOpen(false)
        }, (error)=>{
            setEvents( null )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }, [user, setUser])

    useEffect(listEvents, [listEvents])

    return (
        <Container maxW={'6xl'} mt={10}>
            <Stack spacing={4}>
                <Flex>
                    <Title>Events</Title>
                    <Spacer />
                    { !events && (<Spinner />)}
                    { events && <AddButton onClick={()=>setModalIsOpen(true)} /> }
                </Flex>
                { events && (
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Name</Th>
                                <Th>User</Th>
                                <Th><Center>Starts at</Center></Th>
                                <Th><Center>Ends at</Center></Th>
                                <Th><Center>WhatsApp</Center></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                events.sort( (a, b) => (a.createdAt > b.createdAt) ? 1 : -1 ).map( item => {
                                    return (
                                        <Tr key={item.id}>
                                            <Td><Link as={RouterLink} to={`events/${item.id}`} >{item.name}</Link></Td>
                                            <Td><Link as={RouterLink} to={`users/${item.user_id}`}>{item.user_name}</Link></Td>
                                            <Td><Center>{ dayjs(item.startsAt).toDate().toLocaleString() }</Center></Td>
                                            <Td><Center>{ dayjs(item.endsAt).toDate().toLocaleString() }</Center></Td>
                                            <Td><Center>{item.whatsAppGroup ? <CheckIcon /> : <CloseIcon /> }</Center></Td>
                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </Table>
                )}
            </Stack>

            <ModalForm isOpen={isModalOpen} onClose={()=>setModalIsOpen(false)} title="Create event">
                <EventForm onSuccess={(json)=>history.push(`/events/${json.item.id}`)} />
            </ModalForm>
        </Container>
    )
}

export default Events