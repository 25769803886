import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Navigation from './components/navigation/Navigation'
import ProtectedRoute from './components/router/ProtectedRoute'
import AdminRoute from './components/router/AdminRoute'

import Home from './pages/home/Home'
import Events from './pages/events/Events'
import Event from './pages/events/Event'
import Cameras from './pages/cameras/Cameras'
import Camera from './pages/cameras/Camera'
import Users from './pages/users/Users'
import User from './pages/users/User'
import Settings from './pages/settings/Settings'
import SignIn from './pages/signin/SignIn'
import SignUp from './pages/signup/SignUp'
import SignOut from './pages/signout/SignOut'
import Privacy from './pages/privacy/Privacy'

import { UserContext } from './contexts/UserContext'
import { ChakraProvider } from "@chakra-ui/react"

import {
    Box,
    useColorModeValue
} from '@chakra-ui/react';

function App() {
  const [user, setUser] = useState(null)

  return (
    <Router>
      <ChakraProvider>
        <UserContext.Provider value={{user, setUser}}>
          <Box bg={useColorModeValue('gray.50', 'gray.800')} minH="100vh">
            <Navigation />
            <Switch>
              <ProtectedRoute path="/events/:id" component={Event} />
              <ProtectedRoute path="/events" component={Events} />
              <ProtectedRoute path="/cameras/:id" component={Camera} />
              <ProtectedRoute path="/cameras" component={Cameras} />
              <ProtectedRoute path="/users/:id" component={User} />
              <ProtectedRoute path="/users" component={Users} />
              <AdminRoute path="/settings" component={Settings} />
              <Route path="/settings" component={Settings} />
              <Route path="/signin" component={SignIn} />
              <Route path="/signup" component={SignUp} />
              <Route path="/signout" component={SignOut} />
              <Route path="/privacy" component={Privacy} />
              <Route path='/survey' component={() => { 
                    window.location.replace('https://docs.google.com/forms/d/e/1FAIpQLSeVGOOVyPydEA1TsZFWxHdbLPB_AQpvxUrTq4h4sozedV_xAw/viewform'); 
                    return null;
              }}/>
              <Route path="/" component={Home} />
            </Switch>
          </Box>
        </UserContext.Provider>
      </ChakraProvider>
    </Router>
  );
}

export default App;