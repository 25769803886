import React, { useContext } from 'react';
import { useHistory } from "react-router-dom"

import { UserContext } from '../../contexts/UserContext'

import {
    Box,
    Stack
} from '@chakra-ui/react';

import Title from '../../components/typography/Title'
import SignOutForm from './SignOutForm'
 
const SignOut = () => {

    const { user, setUser } = useContext(UserContext)
    const history = useHistory()
  
    const onSuccess = ( data ) => {
        setUser( null )
        history.push("/");
    }

    return user && (
        <Box px={0} maxW={"md"} m="auto" mt={["10px", "50px", "100px"]}>
            <Stack spacing={4}>
                <Title>Signing Out...</Title>

                <SignOutForm onSuccess={onSuccess} />
            </Stack>
        </Box>
    )
};

export default SignOut