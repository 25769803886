import React, { useContext, useEffect } from 'react'

import { UserContext } from '../../contexts/UserContext'

import {
    Button
} from '@chakra-ui/react';
import {
    CheckIcon,
    CloseIcon
} from '@chakra-ui/icons'

import API from '../../backend/API'

const SettingsSubscription = () => {

    const { user, setUser } = useContext(UserContext)
    const [submitting, setSubmitting] = React.useState(false)
    
    const [subscription, setSubscription] = React.useState(null)

    const getColourForSubscription = ( subscription ) => {
        if ( !subscription ) return 'red'

        switch ( subscription.State ) {
            case 'Enabled':
                return 'green'
            
            case 'Enabling':
            case 'Disabling':
                return 'orange'

            case 'Disabled':
            case null:
            default:
                return 'red'
        }
    }
    const getDisablingForSubscription = ( subscription ) => {
        if ( !subscription ) return true
        
        switch ( subscription.State ) {
            case 'Disabled':
            case 'Enabled':
                return false
            case 'Enabling':
            case 'Disabling':
            case null:
            default:
                return true
        }
    }

    const toggleSubscription = ( action ) => {
        switch ( action ) {
            case 'subscribe':
                API.Queue_Subscribe( {user}, setSubmitting, (json)=>{
                    setSubscription( json.item )
                }, (error)=>{
                    console.log( error )
                    setSubscription( null )
                    if ( API.isRedirectionError(error) ) setUser(null)
                })
                break
            case 'unsubscribe':
                API.Queue_Unsubscribe( {user}, setSubmitting, (json)=>{
                    setSubscription( json.item )
                }, (error)=>{
                    console.log( error )
                    setSubscription( null )
                    if ( API.isRedirectionError(error) ) setUser(null)
                })
                break
            default:
                break
        }
        return;
    }

    useEffect(() => {
        API.Queue_List( {user}, setSubmitting, (json)=>{
            setSubscription( json.item.subscriptions && json.item.subscriptions.length ? json.item.subscriptions[0] : null )
        }, (error)=>{
            console.log( error )
            setSubscription( null )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }, [user, setUser])

    return (
        <Button
            bg={ `${getColourForSubscription(subscription)}.400` }
            fontSize={'sm'}
            fontWeight={600}
            color={'white'}
            _hover={{
                bg: `${getColourForSubscription(subscription)}.300`,
            }}
            disabled={getDisablingForSubscription(subscription)}
            isLoading={ submitting }
            onClick={()=>{
                if ( !getDisablingForSubscription(subscription) ) {
                    toggleSubscription( subscription.State === 'Enabled' ? 'unsubscribe' : 'subscribe' )
                }
            }}
            >
            {subscription && subscription.State} &nbsp; { subscription && subscription.State==='Enabled' ? <CheckIcon /> : <CloseIcon />}
        </Button>
    )
};

export default SettingsSubscription