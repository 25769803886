import React from "react";
 
import {
    Box,
    Heading,
    Container,
    Text,
    Stack,
  } from '@chakra-ui/react';

const Home = () => {
    return (
        <Container maxW={'6xl'} mt={10}>
          <Stack
            as={Box}
            textAlign={'center'}
            spacing={{ base: 8, md: 14 }}
            py={{ base: 20, md: 36 }}>
            <Heading>Camerabooth</Heading>
            <Text  color={'gray.800'}>
              We hope you had a great time at the event and thanks for checking us out.  We're not quite ready to launch, but if you'd like to chat, <a href="tel:+447894082228">give us a call!</a>.
            </Text>
          </Stack>
        </Container>
    )
};

export default Home