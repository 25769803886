import React from 'react';
import {
    Button
} from '@chakra-ui/react';
import {
    AddIcon
} from '@chakra-ui/icons'

const PromoteButton = ( props ) => {
    return <Button
        size="sm"
        bg={'blue.400'}
        color={'white'}
        _hover={{
            bg: 'blue.500',
        }}
        {...props}
        aria-label="Promote"><AddIcon /></Button>
};

export default PromoteButton