import React, { useContext } from 'react'

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import {
    Divider,
    Stack
} from '@chakra-ui/react';

import { UserContext } from '../../../contexts/UserContext'

import TextInput from '../../../components/forms/textinput/TextInput'
import SubmitButton from '../../../components/forms/buttons/SubmitButton'
import AlertError from '../../../components/alerts/AlertError' 

import API from '../../../backend/API'


const WhatsAppSendMessageForm = ( props ) => {

    const event = props.event
    const { user, setUser } = useContext(UserContext)

    const [isAlertOpen, setAlertIsOpen] = React.useState(false)
    const [alertMessage, setAlertMessage] = React.useState('')
 
    const submitForm = (values, { setSubmitting }) => {
        API.Events_WhatsApp_Messages_Send( {user, id:event.id, body:values}, setSubmitting, props.onSuccess, (error)=>{
            setAlertMessage( error )
            setAlertIsOpen( true )
            if ( API.isRedirectionError(error) ) setUser(null)
        })
    }

    return (
        <>
            <Stack spacing={4}>
                <Formik
                    initialValues={{
                        message: '',
                    }}
                    validationSchema={ Yup.object({
                        message: Yup.string().required('Required'),
                    }) }
                    onSubmit={submitForm}
                    >
                    { formik => (
                        <Form id="input-form">
                            <Stack spacing={4}>
                                <TextInput
                                    label="Message"
                                    name="message"
                                    type="text"
                                    isRequired
                                    autoComplete="off"
                                />

                                <Divider />
    
                                <SubmitButton label="Send" isLoading={ formik.isSubmitting }/>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Stack>

            <AlertError isOpen={isAlertOpen} setIsOpen={setAlertIsOpen} title="Error" message={alertMessage} />
        </>
    );
}

export default WhatsAppSendMessageForm